<script>
    import EchartCom from './echart.vue'

    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_info', 'edit_time'],
        components: {EchartCom},
        data() {
            return {
                labelCol: {span: 10},
                wrapperCol: {span: 14},
                form: {
                    danwei: '',
                    kongbai: '',
                    xiaoshudian_num: '',
                    xishu: '',
                    jieju: '',
                    xielv: '',
                },
                qxlinelist: [],
                creatform: {
                    name: ''
                },
                renameform: {
                    name: ''
                },
                active_key: -1,
                active_item: false,
                addlineinput: false,
                renameinput: false,
                flagname: true,
                CSlinelist: []
            }
        },
        watch: {
            edit_time() {
                this.qxinfo = JSON.parse(JSON.stringify(this.edit_info))
            },
        },
        mounted() {
            this.qxinfo = JSON.parse(JSON.stringify(this.edit_info))
            this.Get_jc_item_curve()
        },
        methods: {
            Get_jc_item_curve(click = 0) {
                this.$sa0.post({
                    url: this.$api('Get_jc_item_curve'),
                    data: {
                        jc_item_id: this.qxinfo.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.qxlinelist = response.data.list;
                            if (this.qxlinelist.length) {
                                this.clickitem(this.qxlinelist[click], click)
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Addline() {
                this.addlineinput = true;
                this.$nextTick(() => {
                    this.$refs.clickPosition.focus()
                })
            },
            Create_jc_item_curve_name() {
                if (this.creatform.name === '') {
                    layer.msg('请输入曲线名称')
                    this.addlineinput = false;
                } else {
                    this.$sa0.post({
                        url: this.$api('Create_jc_item_curve_name'),
                        data: {
                            jc_item_id: this.qxinfo.id,
                            name: this.creatform.name,
                        },
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.Get_jc_item_curve(this.qxlinelist.length)
                                this.addlineinput = false
                                this.creatform.name = ''
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })

                }

            },
            // 删除曲线
            Del_jc_item_curve(item) {
                this.$sa0.post({
                    url: this.$api('Del_jc_item_curve'),
                    data: {
                        id: item.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_jc_item_curve()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 复制副本
            CopylineClick(item) {
                this.$sa0.post({
                    url: this.$api('Copy_jc_item_curve'),
                    data: {
                        jc_item_curve_id: item.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_jc_item_curve()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            editname(key, name) {
                this.flagname = key;
                this.renameform.name = name
                setTimeout(() => {
                    this.$refs[`inputname${key}`][0].focus()
                }, 1)
            },
            // 重命名
            reinput(item, key) {
                this.flagname = -1;
                this.$sa0.post({
                    url: this.$api('Rename_jc_item_curve_name'),
                    data: {
                        jc_item_curve_id: item.id,
                        name: this.renameform.name
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_jc_item_curve(key)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            drawEchart() {
                let data_x = [];
                let data_y = [];
                this.CSlinelist.map((item) => {
                    if (item.nongdu && item.xy_value) {
                        data_x.push(item.nongdu)
                        data_y.push(item.xy_value)
                    }
                })
                this.$refs.ec_ref.drawEchart({
                    title: {
                        text: '',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            },
                        }
                    },
                    xAxis: {
                        name: '浓度',
                        type: 'category',
                        data: data_x
                    },
                    yAxis: {
                        name: '响应值',
                        type: 'value'
                    },
                    series: [
                        {
                            data: data_y,
                            type: 'line',
                            smooth: true
                        },
                    ]
                })
            },
            clickitem(item, key) {
                this.active_key = key;
                this.active_item = item;
                this.$sa0.post({
                    url: this.$api('Get_jc_item_curve_detail'),
                    data: {
                        jc_item_curve_id: item.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            let list = []
                            for (let i = 0; i < 15; i++) {
                                list.push({
                                    id: i + 1,
                                    nongdu: '',
                                    xy_value: '',
                                })
                            }
                            // 曲线取值
                            response.data.jc_item_curve.json_param.map((item, key) => {
                                list[key].nongdu = item.nongdu;
                                list[key].xy_value = item.xy_value;
                            });

                            // 中间form
                            this.CSlinelist = list
                            this.form.danwei = response.data.jc_item_curve.danwei;
                            this.form.kongbai = response.data.jc_item_curve.kongbai;
                            this.form.xiaoshudian_num = response.data.jc_item_curve.xiaoshudian_num;
                            this.form.xishu = response.data.jc_item_curve.xishu;
                            this.form.jieju = response.data.jc_item_curve.jieju;
                            this.form.xielv = response.data.jc_item_curve.xielv;
                            this.drawEchart()

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            doclick() {
                let save_id = this.active_item.id
                let data = {
                    jc_item_id: this.qxinfo.id,
                    danwei: this.form.danwei,
                    kongbai: this.form.kongbai,
                    xiaoshudian_num: this.form.xiaoshudian_num,
                    xishu: this.form.xishu,
                    jieju: this.form.jieju,
                    xielv: this.form.xielv,
                    json_param: this.CSlinelist,
                    jc_item_curve_id: save_id,
                }
                console.log(JSON.stringify(this.CSlinelist))
                this.$sa0.post({
                    url: this.$api('Edit_jc_item_curve'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            this.clickitem(this.active_item, this.active_key)
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            UPlineClick(item) {
                this.$sa0.post({
                    url: this.$api('Set_jc_item_curve'),
                    data: {
                        jc_item_curve_id: item.id,
                        type: 1
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_jc_item_curve()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            DownlineClick(item) {
                this.$sa0.post({
                    url: this.$api('Set_jc_item_curve'),
                    data: {
                        jc_item_curve_id: item.id,
                        type: 2
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_jc_item_curve()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },

            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
        }
    }
</script>
<template>
    <div>
        <a-drawer title="设置曲线" width="1600px" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div class="linemain">
                <div class="lineSide">
                    <div class="lineSidetitle">
                        <div>曲线名称</div>
                        <div>
                            <a-button type="primary" size="small" @click="Addline()">新增曲线</a-button>
                        </div>
                    </div>
                    <div class="lineSidemain">
                        <ul>
                            <li v-for="(item,key) in qxlinelist" :key="key" :class="[active_key === key ? 'active':'']" @click="clickitem(item,key)">
                                <div class="linename" @dblclick='editname(key,item.name)'>
                                    <span v-if='flagname !== key' style="display: inline-block">{{item.name}}</span>
                                    <span v-show='flagname === key' style="display: inline-block"><a-input :ref="`inputname${key}`" placeholder="曲线名称" v-model="renameform.name" @blur='reinput(item,key)' class="noborder" style="width: 100%; padding: 0 "/></span>
                                </div>
                                <div class="linemore">
                                    <a-tooltip placement="right">
                                        <template slot="title">
                                            <ul class="tooltipul">
                                                <li @click="UPlineClick(item)" v-if="key !==0">上移</li>
                                                <li @click="DownlineClick(item)" v-if="key !== qxlinelist.length-1">下移
                                                </li>
                                                <li @click="editname(key, name)">重命名</li>
                                                <li @click="CopylineClick(item)">创建副本</li>
                                                <li>
                                                    <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" style="float: right" @confirm="Del_jc_item_curve(item)">
                                                        <template slot="title"> 确认删除该项？</template>
                                                        删除此曲线
                                                    </a-popconfirm>
                                                </li>
                                            </ul>
                                        </template>
                                        <a-icon type="more" class="moreico"/>
                                    </a-tooltip>
                                </div>
                            </li>
                            <li v-if="addlineinput">
                                <div class="linename" style="width: 100%">
                                    <a-input placeholder="曲线名称" v-model="creatform.name" class="noborder" ref="clickPosition" style="width: 95%; margin-left: 2px" @blur="Create_jc_item_curve_name"/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="lineright">
                    <div class="linemid">
                        <table class="layui-table" lay-size="sm" style="margin-top: 0">
                            <thead>
                            <tr>
                                <th width="50">编号</th>
                                <th>标准点浓度</th>
                                <th>响应值</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,key) in CSlinelist" :key="key">
                                <td>{{key+1}}</td>
                                <td>
                                    <a-input placeholder="" v-model="item.nongdu" class="noborder"/>
                                </td>
                                <td>
                                    <a-input placeholder="" v-model="item.xy_value" class="noborder"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="setbtner">
                        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-model-item label="标准点单位:">
                                <a-input class="w100" v-model="form.danwei"/>
                            </a-form-model-item>
                            <a-form-model-item label="标准空白:">
                                <a-input class="w100" v-model="form.kongbai"/>
                            </a-form-model-item>
                            <a-form-model-item label="小数点位数:">
                                <a-input class="w100" v-model="form.xiaoshudian_num"/>
                            </a-form-model-item>
                            <a-form-model-item label="相关系数:">
                                <a-input class="w100" v-model="form.xishu"/>
                            </a-form-model-item>
                            <a-form-model-item label="截距:">
                                <a-input class="w100" v-model="form.jieju"/>
                            </a-form-model-item>
                            <a-form-model-item label="斜率:">
                                <a-input class="w100" v-model="form.xielv"/>
                            </a-form-model-item>
                            <a-form-model-item label="">
                                <div class="linebtn" @click="drawEchart()">绘制曲线</div>
                            </a-form-model-item>
                            <a-form-model-item label="">
                                <div class="linebtn" @click="doclick()">保存</div>
                            </a-form-model-item>
                        </a-form-model>
                    </div>
                    <div class="linekuang">
                        <EchartCom ref="ec_ref"></EchartCom>
                    </div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>
<style scoped>
    .linemain{ width: 100%; display: flex;}

    .lineSide{width: 200px; background: #fcfcfc; height: calc(100vh - 110px); border: 1px solid #eee; overflow-y: auto}

    .lineSidetitle{ width: 100%; line-height: 40px; text-align: center; background: #f9f9f9; border-bottom: 1px solid #eeeeee; font-size: 14px; font-weight: bold; color: #333; display: flex; justify-content: space-between; padding: 0 5px}

    .lineSidemain{ width: 100%; }

    .lineSidemain ul li{ width: 100%; line-height: 40px; padding-left: 10px; border-bottom: 1px solid #eeeeee; cursor: pointer; position: relative; display: flex; justify-content: space-between}

    .linename{ width: 80%; white-space: nowrap;text-overflow: ellipsis;overflow: hidden; }

    .linemore{ width: 20%; text-align: center }

    .linemoremain span{ cursor: pointer}

    .lineSidemain ul li:hover .linemore{ display: block}

    .moreico{
        transform: rotate(90deg);
        opacity: 0.8;
        font-size: 1.2rem;
        transition: 0.3s all;
        margin-top: 5px;
        cursor: pointer
        }

    .tooltipul{ padding: 0; padding-bottom: 10px}

    .tooltipul li{ padding: 5px 10px; cursor: pointer;}

    .linemid{ width: 600px; margin-left: 20px; }

    .lineright{ display: flex; justify-content: space-between; width: 1200px}

    .setbtner{ width: 300px; margin-left: 50px}

    .w100{ width: 100px}

    .linekuang{ width: 600px; border: 1px solid #eeeeee; height: 618px; margin-left: 50px }

    .linebtn{ width: 150px; height: 50px; margin: 0 auto; background: #1890ff;text-align: center; color: #ffffff; font-size: 16px; border-radius: 4px; line-height: 50px; cursor: pointer; margin-left: 50px}

    .noborder{ border: none}

    .active{ background: #f1f8ff}

</style>
