<template>
    <div>
        <a-form-model layout="inline" @submit.native.prevent>
            <a-form-model-item label="项目名称">
                <a-input v-model="formInline.item_name" placeholder="请输入检测项目名称、拼音、首字母进行搜索" class="w300" @keyup="Get_jc_item"></a-input>
            </a-form-model-item>
            <a-form-model-item label="检验方法">
                <a-input v-model="formInline.method_name" placeholder="请输入检验方法" @keyup="Get_jc_item"></a-input>
            </a-form-model-item>
            <a-form-model-item label="检验标准">
                <a-input v-model="formInline.check_bz_name" placeholder="请输入检验标准" @keyup="Get_jc_item"></a-input>
            </a-form-model-item>
            <a-form-model-item label="">
                <a-radio-group name="radioGroup" v-model="formInline.status" @change="Get_jc_item">
                    <a-radio :value="1">
                        使用中
                    </a-radio>
                    <a-radio :value="2">
                        已停用
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="">
                <a-button type="danger" @click="Clear()"> 清空搜索</a-button>
            </a-form-model-item>
        </a-form-model>
        <div>
            <table class="layui-table" lay-size="sm">
                <thead>
                <tr>
                    <th>检测项目名称</th>
                    <th>检验方法</th>
                    <th>检验标准</th>
                    <th>标准值</th>
                    <th>单位</th>
                    <th>状态</th>
                    <th>曲线数量</th>
                    <th>备注</th>
                    <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,key) in Get_jc_itemlist" :key="key">
                    <td>{{item.item_name}}</td>
                    <td>{{item.method_name}}</td>
                    <td>{{item.check_bz_name}}</td>
                    <td>{{item.upper}}-{{item.limit}}</td>
                    <td>{{item.factor_unit_name}}</td>
                    <td>{{item.status===1?'使用中':'已停用'}}</td>
                    <td>{{item.jc_item_curve_num}}</td>
                    <td>{{item.remark}}</td>
                    <td>
                        <a-button type="link" class="" @click="showDrawer(item)"> 设置曲线</a-button>
                    </td>
                </tr>
                </tbody>
            </table>
             <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
        </div>
        <!--设置弹窗-->
        <div v-if="edit_info">
            <Setline :edit_time="edit_time" :edit_info="edit_info" :close="onCloseDrawer" :visible="drawer_visible"></Setline>
        </div>
    </div>
</template>
<script>
    import Setline from "./setline/setline";
    export default {
        components: {
            Setline
        },
        name: "lihualine",
        data() {
            return {
                formInline: {
                    item_name: '',
                    method_name: '',
                    status: '',
                    check_bz_name: '',
                },
                drawer_visible: false,
                Get_jc_itemlist: [],
                // 分页
                page_show: false,
                page: 1,
                count: 0,
                pagesize: 0,
                edit_time: 0,
                edit_info: false,
            }
        },
        watch: {
            $route: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            },
        },
        mounted() {
            this.mountedDo()
        },
        methods: {
            mountedDo() {
                this.Get_jc_item();
            },
            Get_jc_item() {
                this.$sa0.post({
                    url: this.$api('Get_jc_item'),
                    data: {
                        page: this.page,
                        item_name: this.formInline.item_name,
                        method_name: this.formInline.method_name,
                        status: this.formInline.status,
                        check_bz_name: this.formInline.check_bz_name,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_jc_itemlist = response.data.result.list;
                            this.count = response.data.result.count;//分页
                            this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            onChange(page) {
                this.page = page;
                this.Get_jc_item() //领用记录   获取列表记录
            },
            // 清空搜索
            Clear() {
                this.formInline.item_name = '';
                this.formInline.method_name = '';
                this.formInline.status = '';
                this.formInline.check_bz_name = '';
                this.page = 1;
                this.Get_jc_item();
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
                this.Get_jc_item()
            },
            // 定义 打开抽屉时的 函数
            showDrawer(item) {
                this.edit_info = false
                setTimeout(() => {
                    this.edit_time = new Date() / 1;
                    this.edit_info = item;
                    this.drawer_visible = true;
                })
            },
        }
    }
</script>
<style scoped>
    .w300{ width: 300px}
</style>
