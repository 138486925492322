<template>
    <div>
        <div id="main_c"></div>
    </div>
</template>

<script>
    export default {
        name: "echart",
        methods: {
            drawEchart(option) {
                var chartDom = document.getElementById('main_c');
                var myChart = echarts.init(chartDom);
                option && myChart.setOption(option);
            }
        },
    }
</script>

<style scoped>
    #main_c{
        width: 500px;
        height: 600px;
        margin-left: 60px;
        }
</style>
